import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { shortenWalletId } from "../Normalized";
import moment from "moment";
const ShipingDrawer = ({ shipinfo }) => {
  const [copyMsg, setCopyMsg] = useState("Copy");
  const [tooltipVisible, setTooltipVisible] = useState({});

  const handleCopyWalletId = (walletId, dataIndex) => {
    navigator.clipboard.writeText(walletId);
    setTooltipVisible({ [dataIndex]: true });
    setCopyMsg("Copied");

    // Hide the tooltip after a short delay
    setTimeout(() => {
      setTooltipVisible({ [dataIndex]: false });
      setCopyMsg("Copy");
    }, 1000);
  };

  const DUMMYDATA = [
    {
      lots: "Abc Name",
      quantity: "Chandigrah",
      TxID: "0c34698ysdfhjsdfgy6753254632754",
      quantity: "1200",
      createdDate: "01-01-2023",
      expireDate: "02-02-2023",
    },
    {
      lots: "Abc Name",
      quantity: "Chandigrah",
      TxID: "0c34698ysdfhjsdfgy6753254632754",
      quantity: "2000",
      createdDate: "01-01-2023",
      expireDate: "02-02-2023",
    },
    {
      lots: "Abc Name",
      quantity: "Chandigrah",
      TxID: "0c34698ysdfhjsdfgy6753254632754",
      quantity: "1200",
      createdDate: "01-01-2023",
      expireDate: "02-02-2023",
    },
    {
      lots: "Abc Name",
      quantity: "Chandigrah",
      TxID: "0c34698ysdfhjsdfgy6753254632754",
      quantity: "3000",
      createdDate: "01-01-2023",
      expireDate: "02-02-2023",
    },
    {
      lots: "Abc Name",
      quantity: "Chandigrah",
      TxID: "0c34698ysdfhjsdfgy6753254632754",
      quantity: "2000",
      createdDate: "01-01-2023",
      expireDate: "02-02-2023",
    },
  ];

  return (
    <>
      <div className="product-list-drawer-table">
        <Table responsive hover>
          <thead>
            <tr>
              <th> Name </th>
              <th> Address</th>
              <th>Transaction Hash</th>
              <th> Quantity </th>
              <th>Sale Dates </th>
              <th>Expire Dates</th>
            </tr>
          </thead>
          <tbody>
            {shipinfo.alldata?.length === 0 ? (
              <tr>
                <td
                  style={{ textAlign: "center", fontSize: "18px" }}
                  colSpan={8}
                >
                  No Data Found
                </td>
              </tr>
            ) : (
              shipinfo.alldata?.map((data, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td> {data.name} </td>
                      <td className="color-primary"> {data.address} </td>
                      <td>
                        {shortenWalletId(data.transactionId, 6, 4)}
                        <span tooltip={copyMsg} flow="up">
                          <img
                            onClick={() => handleCopyWalletId(data.transactionId, index)}
                            className="copy-btn"
                            src="/images/icons/copy-icon.svg"
                            alt="icon"
                          />
                        </span>
                      </td>
                      <td>{data.amount}</td>
                      <td>{moment(data.shipDate).format("YYYY-MM-DD")}</td>
                      <td className="expire-date-color">{moment(data.ExpireDate).format("YYYY-MM-DD")}</td>
                    </tr>
                  </>
                );
              })
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ShipingDrawer;
