import React from "react";
import { Link, useHistory } from "react-router-dom";

function LogoutPage() {
  const history = useHistory();

  function onLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.clear();
    history.push("/login");
    window.location.reload();
  }
  return (
    <>
      <Link className="dropdown-item ai-icon" onClick={onLogout}>
        <button className="logout-button">Logout </button>
      </Link>
    </>
  );
}

export default LogoutPage;
