import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const LoginTutModal = (props) => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleProceedClick = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const history = useHistory();

  const setLocalStorageData = () => {
   
    
    props.onHide();
   
  };

  return (
    <>
      <Modal
        {...props}
        dialogClassName="login-tutorial-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="login-tutorial-main-wrapper">
            <div className="login-tutorial-main-wrap pharma-login-tutorail-wrap">
              <div className="close-drawer-wrap">
                <img
                  onClick={setLocalStorageData}
                  // onClick={() => props.onHide()}
                  src="/images/icons/cross-icon.svg"
                />
              </div>
              {currentStep === 1 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-wrap">
                      <div className="login-manu-logo">
                        <img src="/images/icons/manu-logo.png" alt="logo" />
                      </div>
                      <div className="login-tutorail-contant-wrap">
                        <h3> Welcome to the Pharma ERP </h3>
                        <p>
                          In the dynamic realm of pharmaceutical retail,
                          blockchain technology stands as a beacon of
                          transformation. Its adoption empowers pharmaceutical
                          wholesalers, fortifying GDP compliance, ensuring
                          real-time supply chain visibility, expediting batch
                          recalls, reducing risks, and safeguarding reputations.
                          Automated compliance processes enhance regulatory
                          adherence, while robust data security shields
                          intellectual property. This steadfast dedication to
                          quality and transparency cultivates stakeholder trust,
                          enabling wholesalers to deliver authentic, safe
                          products and achieve operational excellence in
                          pharmaceutical distribution.
                        </p>
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-1"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="dots-wrap active"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-wrap">
                      <div className="login-manu-logo">
                        <img src="/images/icons/manu-logo.png" alt="logo" />
                      </div>
                      <div className="login-tutorail-contant-wrap">
                        <h3> Welcome to the Pharma ERP </h3>
                        <p>
                          In the dynamic realm of pharmaceutical retail,
                          blockchain technology stands as a beacon of
                          transformation. Its adoption empowers pharmaceutical
                          wholesalers, fortifying GDP compliance, ensuring
                          real-time supply chain visibility, expediting batch
                          recalls, reducing risks, and safeguarding reputations.
                          Automated compliance processes enhance regulatory
                          adherence, while robust data security shields
                          intellectual property. This steadfast dedication to
                          quality and transparency cultivates stakeholder trust,
                          enabling wholesalers to deliver authentic, safe
                          products and achieve operational excellence in
                          pharmaceutical distribution.
                        </p>
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-1"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="dots-wrap active"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Second Step */}
              {currentStep === 2 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Dasboard </h3>
                        <p>
                          Hyperledger serves as digital glue, linking software
                          systems like a bridge between islands. It quietly
                          maintains your ERP system as the central business hub,
                          enabling secure data flow among systems, fostering
                          collaboration without major adjustments or additional
                          apps.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/dashboard-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Dasboard </h3>
                        <p>
                          Hyperledger serves as digital glue, linking software
                          systems like a bridge between islands. It quietly
                          maintains your ERP system as the central business hub,
                          enabling secure data flow among systems, fostering
                          collaboration without major adjustments or additional
                          apps.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img
                          src="/images/mobile-dashboard-demo.png"
                          alt="img"
                        />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Forth Step */}
              {currentStep === 3 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Product List </h3>
                        <p>
                          Hyperledger Fabric ensures meticulous recording of
                          pharmaceutical data on a tamper-proof blockchain,
                          enhancing transparency, traceability, compliance, and
                          patient safety in the supply chain.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/product-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-3"> 3 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Product List </h3>
                        <p>
                          Hyperledger Fabric ensures meticulous recording of
                          pharmaceutical data on a tamper-proof blockchain,
                          enhancing transparency, traceability, compliance, and
                          patient safety in the supply chain.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/mobile-product-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-3"> 3 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Fifth Step */}
              {currentStep === 4 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Sales Orders </h3>
                        <p>
                          Hyperledger Fabric revolutionizes pharma retail
                          logistics with secure blockchain records, streamlining
                          processes, enhancing compliance, minimizing risks,
                          ensuring authenticity, and benefiting pharma.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/shipping-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-4"> 4 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Sales Orders </h3>
                        <p>
                          Hyperledger Fabric revolutionizes pharma retail
                          logistics with secure blockchain records, streamlining
                          processes, enhancing compliance, minimizing risks,
                          ensuring authenticity, and benefiting pharma.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/mobile-shipping-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-4"> 4 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Sixth Step */}
              {currentStep === 5 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Orders History</h3>
                        <p>
                          Hyperledger Fabric ensures secure, transparent,
                          tamper-proof Orders History, enhancing compliance,
                          authenticity, and patient safety in pharmaceutical
                          retail logistics. It fosters unwavering data integrity
                          and trust, safeguarding the pharmaceutical supply
                          chain.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img
                          src="/images/shipping-history-demo.png"
                          alt="img"
                        />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-5"> 5 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Orders History </h3>
                        <p>
                          Hyperledger Fabric ensures secure, transparent,
                          tamper-proof Orders History, enhancing compliance,
                          authenticity, and patient safety in pharmaceutical
                          retail logistics. It fosters unwavering data integrity
                          and trust, safeguarding the pharmaceutical supply
                          chain.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img
                          src="/images/mobile-shipping-history-demo.png"
                          alt="img"
                        />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-5"> 5 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Sixth Step */}
              {currentStep === 6 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Explorer </h3>
                        <p>
                          Hyperledger Fabric ensures secure, transparent,
                          tamper-proof shipping records, enhancing compliance,
                          authenticity, and patient safety in pharmaceutical
                          logistics, fostering unwavering data integrity and
                          trust.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/expolorer-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button
                            className="go-dashboard"
                            onClick={setLocalStorageData}
                          >
                            Go to Dashboard
                          </button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-6"> 6 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div className="dots-wrap active"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Explorer </h3>
                        <p>
                          Hyperledger Fabric ensures secure, transparent,
                          tamper-proof shipping records, enhancing compliance,
                          authenticity, and patient safety in pharmaceutical
                          logistics, fostering unwavering data integrity and
                          trust.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/mobile-explorer-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button
                            className="go-dashboard"
                            onClick={setLocalStorageData}
                          >
                            Go to Dashboard
                          </button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-6"> 6 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div className="dots-wrap active"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginTutModal;
