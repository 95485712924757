import React, { useState } from "react";

import { Link, useLocation } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/avatar/pic1.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import logo from "../../../images/logo.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ExplorerTutModal from "../../components/Modals/ExplorerTutModal";

const Header = ({ onNote }) => {
  const [showExTutorialModal, setShowExTutorialModal] = useState(false);
  const history = useHistory();
  function onLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.clear();
    history.push("/login");
    window.location.reload();
  }

  const location = useLocation();
  var path = location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  return (
    <>
      <ExplorerTutModal
        show={showExTutorialModal}
        onHide={() => setShowExTutorialModal(false)}
      />

      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div
                  className="dashboard_bar"
                  style={{ textTransform: "capitalize" }}
                >
                  {/* {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ") === "dashboard dark"
                  ? "Dashboard"
                  : finalName.join(" ")} */}
                  {location.pathname == "/" || location.pathname == "/dashboard"
                    ? "Dashboard"
                    : location.pathname == "/inventory"
                    ? "Product List"
                    : location.pathname == "/Shipping"
                    ? "Sales Orders"
                    : location.pathname == "/shiping-history"
                    ? "Orders History"
                    : ""}
                </div>
              </div>
            </div>
            <div className="sidebar-explorer-logo-wrap">
              <div className="explorer-left-img">
                <img
                  onClick={() => setShowExTutorialModal(true)}
                  className="hyper-big-logo"
                  src="/images/icons/explorer-logo.png"
                  alt="logo"
                />

                <img
                  onClick={() => setShowExTutorialModal(true)}
                  className="hyper-small-logo"
                  src="/images/icons/hyper-small.png"
                  alt="logo"
                />
              </div>
              <div className="explorer-right-img">
                <img
                  onClick={() => setShowExTutorialModal(true)}
                  src="/images/icons/explorer-arrow.svg"
                  alt="logo"
                />
              </div>
              <div className="logout-main-wrapper">
                <div className="logout-main-wrap">
                  <img
                    onClick={onLogout}
                    src="/images/icons/logout-icon.svg"
                    alt="icon"
                  />
                  <p onCanPlay={onLogout}> Logout </p>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
