import React, { useState, Fragment, useEffect, useRef } from "react";
import { serviceGet, servicePost } from "../../../services/api";
import arrow from "../../../images/down-arrow.png";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ShippingTutModal from "../Modals/ShippingTutModal";
import GyanConsulting from "./GyanConsulting";

const Shipping = () => {
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Select Product");
  const productVal = selected == "Select Product" ? "" : selected;

  // Lot dropdown
  const [isLotActive, setIsLotActive] = useState(false);
  const [selectedLot, setSelectedLot] = useState("Select Lots");
  //const lot = selectedLot == "Select Lots" ? "" : selectedLot;
  const [lotnum, setLot] = useState();
  const [users, setUsers] = useState([]);
  const [shipDate, setShipDate] = useState();
  const [expireDate, setExpireDate] = useState();
  const [skuNumber, setSkuNumber] = useState();
  const [shipData, setShipData] = useState({
    // skuNumber: "",
    name: "",
    insurance: "",
    policy: "",
    amount: 0,
    address: "",
  });

  // Get user form localStorage
  const userData = JSON.parse(localStorage.getItem("userData"));

  const { name, amount, insurance, policy, address } = shipData;

  const history = useHistory();
  const refOne = useRef(null);
  const today = new Date().toISOString().split("T")[0];

  // Access Dom and When Click Outside Close DropDowns...
  useEffect(() => {
    const hideOnClickOutside = (e) => {
      if (refOne.current && !refOne.current.contains(e.target)) {
        setIsActive(false);
        setIsLotActive(false);
      }
    };

    document.addEventListener("click", hideOnClickOutside);
    return () => {
      document.removeEventListener("click", hideOnClickOutside);
    };
  }, []);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setShipData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setSelectedLot("Select Lots");
  }, [selected]);

  // get all products
  const getAllProducts = async () => {
    try {
      const allProducts = await serviceGet("api/pharma/allproducts");
      setProducts(allProducts);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  // get all users
  const getAllUsers = async () => {
    try {
      const allusers = await serviceGet("api/getUsers");
      setUsers(allusers);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  // filterData basis of userEmail
  const filterData = products?.filter((data) => {
    return data.organization == userData.email;
  });

  // get uniqueProducts..
  const uniqueProducts = products?.reduce((acc, current) => {
    if (!acc[current.product]) {
      acc[current.product] = current;
    }
    return acc;
  }, {});
  const uniqueProduct = Object.values(uniqueProducts);

  // get Selected Product value...
  const selectedProduct = products.filter((data) => {
    return data.product == productVal;
  });

  console.log("selectedProduct", selectedProduct);

  useEffect(() => {
    if (selectedProduct) {
      setExpireDate(
        selectedProduct[0]?.expireDate ? selectedProduct[0]?.expireDate : ""
      );

      setSkuNumber(
        selectedProduct[0]?.skuNumber ? selectedProduct[0]?.skuNumber : ""
      );
    }
  }, [selectedProduct]);

  // Create Ship
  const createShipHandler = async () => {
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }

    if (formatDate(Date.now()) > formatDate(expireDate)) {
      alert("This Product expired");
      return;
    }

    if (
      productVal == "" ||
     // lot == "" ||
      address == "" ||
      //policy == "" ||
      name == "" ||
      amount == "" ||
     // insurance == "" ||
      shipDate == "" ||
      shipDate == undefined ||
      expireDate == ""
    ) {
      alert("Please fill all Fields");
      return;
    }

    if (amount == 0) {
      alert("Please Enter amount grater then 0");
      return;
    }

    try {
      setIsLoading(true);
      const convertdateformat = new Date(expireDate);
      const convertedDate = convertdateformat.toISOString().slice(0, 10);
      console.log("convertedDate", convertedDate);
      const data = await servicePost("api/createShip", {
        product: productVal,
        organization: "pharma",
        skuNumber: skuNumber,
        lot: lotnum,
        amount: amount,
        name: name,
        insurance: insurance,
        policy: policy,
        address: address,
        shipper: "pharma",
        trackingNo: "pharma",
        shipDate: shipDate,
        expireDate: convertedDate,
        from: "Pharma",
        to: "client",
      });

      if (data) {
        history.push("/shiping-history");
        setIsLoading(false);
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        alert(err.response.data.message);
        setIsLoading(false);
      } else {
        alert("Something went wrong");
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  /// HandleKeyPress
  function handleKeyPress(event) {
    const regex = /^[0-9\b]+$/; // allows only numbers and backspace
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  // hadleKey allow only Char

  function handleKeyCharPress(event) {
    const regex = /^[a-zA-Z ]+$/; // allows only characters and space
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  // Increment and Decrement Quantity
  const incrementAmount = () => {
    setShipData((prevData) => ({
      ...prevData,
      amount: Number(prevData.amount) + 1,
    }));
  };

  const decrementAmount = () => {
    if (shipData.amount > 0) {
      setShipData((prevData) => ({
        ...prevData,
        amount: Number(prevData.amount) - 1,
      }));
    }
  };

  return (
    <>
      <ShippingTutModal
        show={showTutorialModal}
        onHide={() => setShowTutorialModal(false)}
      />

      <div className="create-product-main-wraaper">
        <div className="mobile-create-tutorial-wrap mobile-shipping-tutorial-wrap">
          <div className="tutorial-wrap-btn">
            <img
              onClick={() => setShowTutorialModal(true)}
              src="/images/icons/tutorial-icon.png"
              alt="icon"
            />
            <p> Tutorials </p>
          </div>
        </div>
        <div className="create-product-main-wrap">
          <div className="create-product-right-wrap shipping-product-form">
            <div className="card1">
              <div className="card-body">
                <form>
                  <div className="create-product-form row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="select-box-wrapper shipping-dropdown">
                        <label>Product</label>
                        <div className="react-dropdown">
                          <div
                            className="react-dropdown-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsActive(!isActive);
                              setIsLotActive(false);
                            }}
                          >
                            <div className="selected-item">
                              <p>
                                <div className="input-icon-wrap">
                                  <img
                                    src="/images/icons/input-1.svg"
                                    alt="icon"
                                  />
                                </div>
                                {selected}
                              </p>
                            </div>

                            <img
                              className={
                                isActive
                                  ? "dropdown-down-arrow rotate-arrow"
                                  : "dropdown-down-arrow"
                              }
                              src="/images/icons/down-arrow.svg"
                              alt="arrow"
                            />
                          </div>
                          {isActive && (
                            <div className="dropdown-content" ref={refOne}>
                              {uniqueProduct &&
                                uniqueProduct?.map((option, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <div
                                        onClick={(e) => {
                                          setSelected(option.product);
                                          setLot(option.lot);
                                          setIsActive(false);
                                        }}
                                        className="dropdown-item"
                                      >
                                        <p> {option.product} </p>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Quantity
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input quantity-input-wrap">
                        <input
                          name="amount"
                          className="input-field-redux"
                          type="number"
                          onChange={onChangeHandler}
                          onKeyPress={handleKeyPress}
                          value={amount}
                          min={0}
                        />
                        <div className="increment-icon">
                          <img
                            onClick={incrementAmount}
                            src="/images/icons/increment-icon.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="decrement-icon">
                          <img
                            onClick={decrementAmount}
                            src="/images/icons/decrement-icon.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-6.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Address*
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="address"
                          placeholder="Enter Address"
                          className="input-field-redux"
                          value={address}
                          onChange={onChangeHandler}
                        />
                        <div className="input-icon-wrap">
                          <img
                            src="/images/icons/address-input.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Insurance*
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>

                      <div className="create-product-input">
                        <input
                          name="insurance"
                          placeholder="Enter insurance"
                          className="input-field-redux"
                          value={insurance}
                          onChange={onChangeHandler}
                        />
                        <div className="input-icon-wrap">
                          <img
                            src="/images/icons/insurance-icon.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Policy*
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="policy"
                          placeholder="Enter policy"
                          className="input-field-redux"
                          value={policy}
                          onChange={onChangeHandler}
                        />
                        <div className="input-icon-wrap">
                          <img
                            src="/images/icons/policy-input.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="select-box-wrapper shipping-dropdown">
                        <label>Lots</label>
                        <div className="react-dropdown">
                          <div
                            className="react-dropdown-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsLotActive(!isLotActive);
                              setIsActive(false);
                            }}
                          >
                            <div className="selected-item">
                              <p>
                                <div className="input-icon-wrap">
                                  <img
                                    src="/images/icons/input-1.svg"
                                    alt="icon"
                                  />
                                </div>
                                {productVal
                                  ? selectedLot
                                  : "Select product first"}
                              </p>
                            </div>

                            <img
                              className={
                                isLotActive && productVal
                                  ? "dropdown-down-arrow rotate-arrow"
                                  : "dropdown-down-arrow"
                              }
                              src="/images/icons/down-arrow.svg"
                              alt="arrow"
                            />
                          </div>
                          {isLotActive && productVal && (
                            <div className="dropdown-content" ref={refOne}>
                              {filterData &&
                                filterData
                                  .filter((data) => {
                                    return data.product == selected;
                                  })
                                  .map((option, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <div
                                          onClick={(e) => {
                                            setSelectedLot(option.lot);
                                            setIsLotActive(false);
                                          }}
                                          className="dropdown-item"
                                        >
                                          <p> {option.lot} </p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div> */}

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        SKU Number
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="skuNumber"
                          type="text"
                          // placeholder="Enter sku number"
                          onKeyPress={handleKeyPress}
                          className="input-field-redux"
                          onChange={onChangeHandler}
                          value={skuNumber ? skuNumber : "Select Product First"}
                          // value="Select Product First"
                          // min={0}
                        />
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-5.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Sale date
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <input
                        type="date"
                        min={today}
                        onChange={(e) => setShipDate(e.target.value)}
                      />
                      {/* <DatePicker onChange={onChange} /> */}
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Name*
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="name"
                          placeholder="Enter name"
                          className="input-field-redux"
                          value={name}
                          onChange={onChangeHandler}
                        />
                        <div className="input-icon-wrap">
                          <img src="/images/icons/name-input.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    {expireDate ? (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label>
                          Expire date
                          <img src="/images/icons/info-icon.svg" alt="icon" />
                        </label>
                        <input
                          type="date"
                          name="expire date"
                          className="input-field-redux expire-date-input"
                          value={
                            expireDate
                              ? moment(expireDate).format("YYYY-MM-DD")
                              : ""
                          }
                          disabled
                        />
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label> Expire date </label>
                        <div className="create-product-input">
                          <input
                            className="input-field-redux"
                            value="Select Product First"
                          />
                          <div className="input-icon-wrap">
                            <img src="/images/icons/input-8.svg" alt="icon" />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-sm-12 text-center">
                      <button
                        className="create-product-btn"
                        onClick={createShipHandler}
                        disabled={isLoading}
                      >
                        {isLoading ? "Processing..." : "Proceed"}
                      </button>
                      <div className="tutorial-wrap-btn tutorial-tab-etc">
                        <img
                          onClick={() => setShowTutorialModal(true)}
                          src="/images/icons/tutorial-icon.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="create-product-left-wrap">
            <div className="create-product-left">
              <div className="create-product-left-img">
                <img src="/images/shipping-img.png" alt="img" />
              </div>
              <div className="create-product-left-contant">
                {/* <h3>Welcome to the Shipping Creation Page </h3> */}

                <p>
                  Hyperledger Fabric's 'Shipping' streamlines pharmaceutical
                  product delivery. It enables product, distributor, lot,
                  tracking, quantity, and ship date selection. By securely
                  recording this on the blockchain, it ensures compliance and
                  efficiency in pharmaceutical supply chains.
                </p>

                <div className="toutorial-icon-wrap">
                  <img
                    onClick={() => setShowTutorialModal(true)}
                    src="/images/icons/tutorial-icon.png"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GyanConsulting />
    </>
  );
};

export default Shipping;
