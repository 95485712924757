import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { servicePost } from "../../services/api";
import { storeToken } from "../../store/actions/AuthActions";
import LoginTutModal from "../components/Modals/LoginTutModal";
import loginbg from "../../images/login-bg.jpeg";
import { useHistory } from "react-router-dom";

function Login(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState();
  const [userData, setUserData] = useState();
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  var [email, setEmail] = useState("");
  const [errors, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    email: "",
    password: "",
    serverError: "",
  });
  var [password, setPassword] = useState("");
  const history = useHistory();

  const dispatch = useDispatch();

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      email="Pharmacy";
      password="Pharm@123";
      if (email === "") {
        setErrorMsg({ password: "", email: "Email is Required" });
        setError(true);
        return;
      }
      if (password === "") {
        setErrorMsg({ password: "Password is Required" });
        setError(true);
        return;
      }

      setIsLoading(true);

      const data = await servicePost("api/login", {
        email,
        password,
        organization: "Pharma",
      });

      setShowTutorialModal(true);

      if (data.userData.token) {
        //setShowTutorialModal(true);
        setToken(data.userData.token);
        setUserData(JSON.stringify(data.userData));
         localStorage.setItem("token", data.userData.token);
        localStorage.setItem("userData", JSON.stringify(data.userData));
        setError(true);
        //if(showTutorialModal===false){
         history.push("/dashboard");
        // window.location.reload();
        setIsLoading(false);
        dispatch(storeToken(data.userData.token));
      }
      // setIsLoading(false);
    } catch (err) {
      setShowTutorialModal(false);
      console.log(err);
      setError(true);
      setErrorMsg({
        email: "",
        password: "",
        serverError: err.response?.data.message,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setError(false);
      setErrorMsg({ email: "", password: "", serverError: "" });
    }, 2000);
  }, [errors]);

  return (
    <>
      {/* <LoginTutModal
        show={showTutorialModal}
        onHide={() => setShowTutorialModal(false)}
        token={token}
        userData={userData}
      /> */}

      <div className="login-main-contant-wrapper">
        <div className="login-main-wrapper">
          <div className="login-left-wrap">
            <img src="/images/login-img.png" alt="img" />
          </div>
          <div className="login-right-wrap">
            <div className="login-manu-logo-wrap">
              <img src="/images/icons/manu-logo.png" alt="img" />
            </div>
            <div className="manu-login-detail-wrap">
              <div id="sign-in" className="auth-form   form-validation">
                {props.errorMessage && (
                  <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                    {props.errorMessage}
                  </div>
                )}
                {props.successMessage && (
                  <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                    {props.successMessage}
                  </div>
                )}
                <form onSubmit={onLogin} className="form-validate">
                  <div className="login-text-wrap">
                    <h2>
                      Welcome <span> Back </span>
                    </h2>
                    <h3>Welcome back! Please enter your details.</h3>
                  </div>

                  <div className="form-group mb-3">
                    <div className="email-input-wrap">
                      <input
                        type="text"
                        className="form-control"
                        value={"Pharmacy"}
                       // onChange={(e) => setEmail(e.target.value)}
                        placeholder="Type Your Email Address"
                        readOnly="readOnly"
                      />
                      <div className="email-icon-wrap">
                        <img src="/images/icons/email-icon.svg" alt="icon" />
                      </div>
                    </div>
                    {errorMsg.email && (
                      <div className="text-danger fs-12">{errorMsg.email}</div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <div className="password-input-wrap">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        value={"Pharm@123"}
                        placeholder="Enter your Password"
                       // onChange={(e) => setPassword(e.target.value)}
                       readOnly="readOnly"
                      />

                      <div className="password-icon-wrap">
                        <img src="/images/icons/password-icon.svg" alt="icon" />
                      </div>
                      <div className="passowrd-show-icon">
                        {showPassword ? (
                          <img
                            onClick={toggleShowPassword}
                            src="/images/icons/open-eye.svg"
                            alt="icon"
                          />
                        ) : (
                          <img
                            onClick={toggleShowPassword}
                            src="/images/icons/close-eye.svg"
                            alt="icon"
                          />
                        )}
                      </div>
                    </div>
                    {errorMsg.password && (
                      <div className="text-danger fs-12">
                        {errorMsg.password}
                      </div>
                    )}
                    {errorMsg.serverError && (
                      <div className="text-danger fs-12">
                        {errorMsg.serverError}
                      </div>
                    )}
                  </div>
                  <div className="form-row d-flex justify-content-between mt-4 mb-2">
                    <div className="form-group mb-3"></div>
                  </div>
                  <div className="text-center form-group mb-3">
                    <button
                      // onClick={() => setShowTutorialModal(true)}
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={isLoading}
                    >
                      {isLoading ? "Processing.." : "Login"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
